
.selectBox-container {
    position: relative;
    width: 100%;
}

.selectBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #dee2e6;
    padding: 3px 5px;
    cursor: pointer;
    user-select: none;
    border-radius: 4px;
}

.selectBox.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.selectBox-input {
    width: calc(100% - 16px);
    border: none;
    outline: none;
    font-size: 14px;
}

.selectBox-input::placeholder {
    color: #000;
}

.selectBox-arrow {
    font-size: 10px;
    cursor: pointer;
    padding-right: 2px;
}

.selectBox-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    border-top: none;
    max-height: 200px;
    overflow-y: auto;
    background: #fff;
    z-index: 1;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    font-size: 14px;
    list-style: none;
    padding-left: 0.5rem !important;
    z-index: 5;
}

.selectBox-option {
    padding: 6px 0px;
    /* padding: 8px 12px; */
    cursor: pointer;
}

.selectBox-option:hover,
.selectBox-option.selected {
    background: #f0f0f0;
}

.selectBox-clear {
    position: absolute;
    top: 53%;
    right: 15px;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 10px;
    color: #999;
}

.selectBox-errorStyle {
  font-size: 12px !important;
}

.selectBox-no-options {
    padding: 8px 12px;
    color: #999;
}
