@charset "UTF-8";
:root {
  --ct-menu-item-padding-x: 10px;
}

.sidebar-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 30px;
}

.sidebar-content {
  flex: 1;
  overflow-y: auto;
}

.sidebar-footer {
  margin: 5px;
}

.side-nav {
  padding-left: 0;
  list-style-type: none;
}

.side-nav ul {
  list-style-type: none;
}

.link {
  list-style-type: none;
  text-decoration: none;
}

.side-nav .side-nav-link {
  display: block;
  list-style: none;
  position: relative;
  white-space: nowrap;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  text-decoration: none;
  color: #67748e !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  border-bottom: 1px solid #f9f9f6;
  padding: 8px 5px;
}

.leftside-menu {
  transition: width 0.3s;
  width: 250px; /* Adjust this value to your preferred width */
  padding-top: 15px;
  background-color: white;
}

.leftside-menu.collapsed {
  width: 80px; /* Adjust this value for the collapsed width */
}
.container-expanded {
  width: calc(100% - 250px); /* Full width minus the sidebar width */
  transition: width 0.3s;
}

.container-collapsed {
  width: calc(100% - 80px); /* Full width minus the collapsed sidebar width */
  transition: width 0.3s;
}
.close_icon_mobile {
  display: none;
}

@media (max-width: 991px) {
  .close_icon_mobile {
    display: block;
    position: absolute;
    top: 5px;
    right: 5px;
    color: gray;
  }
  .leftside-menu.collapsed {
    display: none;
  }
  .leftside-menu.ismobile {
    position: absolute;
    z-index: 20;
    width: 250px;
    height: 100%;
  }
  .container-expanded {
    width: 100%; /* Full width minus the sidebar width */
    transition: width 0.3s;
  }
  .leftside-menu {
    transition: width 0.3s;
    width: 60%; /* Adjust this value to your preferred width */
    padding-top: 15px;
    background-color: white;
  }

  .container-collapsed {
    width: 100%; /* Full width minus the collapsed sidebar width */
    transition: width 0.3s;
  }
}

.leftside-menu .side-nav-link {
  display: flex;
  align-items: center;
}

.leftside-menu .side-nav-link span {
  transition: opacity 0.3s;
  opacity: 1;
}

.leftside-menu.collapsed .side-nav-link span {
  opacity: 0;
  width: 0;
  overflow: hidden;
}

.leftside-menu.collapsed .side-nav-link {
  justify-content: center;
}

.leftside-menu.collapsed .side-nav-second-level {
  display: none;
}

.side-nav-second-level .second-level-link {
  list-style-type: none;
  text-decoration: none;
  color: #67748e;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  /* border-bottom: 1px solid #f9f9f6; */
  padding-bottom: 5px;
}

.side-nav-second-level li {
  padding: 5px 0px !important;
  cursor: pointer;
}
.side-nav-second-level-hover .second-level-link {
  list-style-type: none;
  text-decoration: none;
  color: #67748e;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  border-bottom: 1px solid #f9f9f6;
  padding-bottom: 5px;
}

.side-nav-second-level-hover li {
  padding: 5px 0px !important;
}

.second-level-link span {
  margin-left: 13px;
}

.icon-class {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.side-nav .menu-arrow {
  display: inline-block;
  text-rendering: auto;
  position: absolute;
  top: 50%;
  right: calc(var(--ct-menu-item-padding-x) * 1.5);
  -webkit-transition: -webkit-transform 0.15s;
  transition: -webkit-transform 0.15s;
  transition: transform 0.15s;
  transition: transform 0.15s, -webkit-transform 0.15s;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.leftside-menu .logo-container {
  transition: all 0.3s ease;
}

.leftside-menu.collapsed .logo-container {
  width: 70px; /* Adjust as needed */
  padding: 0px 10px; /* Adjust as needed */
}

.leftside-menu .logo-container img {
  transition: all 0.3s ease;
  width: 100%;
}

.leftside-menu.collapsed .logo-container img {
  width: 80%; /*Adjust as needed */
  height: 13px !important;
}
.leftside-menu.collapsed .logo-container p {
  font-size: 13px;
}
.leftside-menu.collapsed .logout-collapse {
  font-size: 13px;
}

.sub-menu-hover {
  position: absolute;
  left: 80px;
  top: 0px;
  background-color: #fbfbfb;
  width: max-content;
  padding-right: 15px;
  z-index: 100;
  box-shadow: 1px 1px 1px 1px #888888;
  ul {
    padding-left: 1rem;
  }
}
.side-nav-item {
  position: relative;
}
