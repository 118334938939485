body {
  margin: 0;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .sidebar-div1 {
  width: 250px;
} */

.sidebar-div2 {
  width: 80px;
}

.container1 {
  width: calc(100% - 250px);
}

.container2 {
  width: calc(100% - 80px);
}

.pagination .page-item .page-link {
  color: #0e8d84;
}

.mb0-pt10 {
  margin-bottom: 0;
  padding-top: 10px;
}

.pagination .page-item.active .page-link {
  background-color: #0e8d84;
  border-color: #0e8d84;
  color: #fff;
}

.p-column-header-content svg {
  color: #0e8d84;
  height: 10px;
  margin-left: 0px;
}

@media screen and (min-width: 992px) {
  .css-nae09q {
    position: relative;
    border-right-width: 1px;
    border-right-style: solid;
    border-color: #efefef;
    -webkit-transition: width, left, right, 800ms;
    transition: 0.3s;
    width: auto !important;
    min-width: auto !important;
  }
}

.icon_hover:hover {
  background-color: black !important;
  color: white !important;
  transition: all 0.8s ease !important;
}

.global_badge_hover:hover {
  background-color: #0e8d84 !important;
  color: white !important;
  transition: all ease !important;
}

.swal2-title {
  color: #595959;
  font-size: 30px;
  font-weight: 600;
  text-transform: none;
  margin: 0 0 0.4em;
  padding: 0;
  display: block;
  word-wrap: break-word;
}

.swal2-custom-button {
  background-color: rgb(48, 133, 214) !important;
  border-left-color: rgb(48, 133, 214) !important;
  border-right-color: rgb(
    48,
    133,
    214
  ) !important; /* Change to your desired color */
  color: white !important;
  border: 0 !important;
  border-radius: 3px !important;
  box-shadow: none;
  padding: 8px 32px;
  font-size: 17px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
}

.swal2-html-container {
  margin-top: 0px !important;
}
.proof-class {
  display: none !important;
}
.proof-close-btn {
  height: 30px !important;
  width: 30px !important;
  padding-bottom: 5px !important;
}

.global-card-btn:hover {
  background-color: #0e8d84 !important;
  transition: 0.9s;
}

.btn-hover:hover {
  background-color: #027970 !important;
  transition: 0.9s;
}

.warning-hover:hover {
  background-color: #e0a800 !important;
  border-color: #d39e00 !important;
}

.inactive-hover:hover {
  background-color: #c82333 !important;
  border-color: #bd2130 !important;
}

.p-datatable-tfoot {
  border-width: 1px !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
}

.table-class {
  z-index: 0 !important;
}

.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #b4b1b1;
}

.not-found-content {
  max-width: 600px;
}

.not-found-title {
  font-weight: 700;
  color: #027970;
}

.select2-container .select2-selection--single {
  height: 30px !important;
  border-color: #dee2e6 !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 29px !important;
  font-size: 0.875rem !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  font-size: 0.875rem !important;
  color: #212529 !important;
}

.swal2-confirm-button {
  display: block !important;
  width: 70% !important;
  margin-bottom: 20px !important;
}

.swal2-container-button .swal2-html-container {
  display: flex !important;
  flex-direction: column !important;
}
.swal2-container-button .swal2-html-container .swal-textarea {
  margin: 1em 1em 3px !important;
}
.swal2-container-button .swal2-popup .swal2-title {
  font-size: 22px !important;
  font-weight: 500 !important;
  display: flex !important;
  margin-left: 20px !important;
  margin-top: 12px !important;
  color: #333333 !important;
  font-family: system-ui !important;
}

.cancelRowData{
  background: #f8d7da !important;
}